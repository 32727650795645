import "./App.css";
import Banner from "./components/Banner";
import Header from "./components/Header";
import Perks from "./components/Perks";
import React from "react";
import Pill from "./components/Pill";
import Prize from "./components/Prize";
import Special from "./components/Special";
import Teams from "./components/Teams";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import RoadMap from "./components/RoadMap";
import StoryMode from "./components/StoryMode";
// import Mint from './components/Mint';

function App() {
  return (
    <>
      <Header />
      <Banner />
      <Prize />
    
      <Pill />
      {/* <Factions /> */}
      <Perks />
      <Special />

      <Teams />
      <RoadMap />
      <StoryMode />
      <Faq />
      <Footer />
    </>
  );
}

export default App;
