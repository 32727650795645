import styled from "styled-components";
// import Button from "../styled/Button";
// import Slider from "react-slick";
import React from "react";
import Paragraph from "../styled/Paragraph";
import Flip from "react-reveal/Flip";
import RubberBand from "react-reveal/RubberBand";

const BannerWrapper = styled.section`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
  padding: 5%;
  position: relative;
  z-index: 1;
  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  @media (min-width: 1200px) {
    padding: 0 10% 5%;
  }
  .banner-inner {
    text-align: center;
    z-index: 1;
    position: relative;
    @media (min-width: 992px) {
      width: 45%;
    }
    h1 {
      color: #ffffff;
      font-size: 20px;
      text-transform: uppercase;
      font-family: ${(props) => props.theme.fam.arial};
      font-weight: bold;
      margin-top: 0;
      z-index: -1;
      position: relative;
      font-weight: 900;
      margin-bottom: 20px;
      @media (min-width: 1200px) {
        font-size: 40px;
      }
    }
    p {
      margin-bottom: 0;
      color: #ffffff;
      z-index: -1;
      position: relative;
      span {
        color: #ffffff;
      }
    }
    a {
      z-index: 5;
      padding: 10px;
    }
    button {
      margin-top: 40px;
    }
  }
  .banner-carousels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0;
    @media (min-width: 992px) {
      width: 45%;
    }
    @media (min-width: 1200px) {
      width: 50%;
    }
  }
  .carousel-inner {
    width: 45%;
    margin-bottom: 30px;
    @media (min-width: 768px) {
      width: 30%;
    }
    .text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 0 0 26px 26px;
      color: #696969;
      background: #ffffff;
      transition: 0.7s;
      opacity: 0;
      h5 {
        font-family: ${(props) => props.theme.fam.avenir};
        font-size: 15px;
        padding: 5px 20px;
        margin: 0;
      }
      p {
        font-family: ${(props) => props.theme.fam.avenir};
        font-size: 12px;
        padding: 5px 20px;
        margin: 0;
      }
    }
  }
  .item {
    width: 200px;
    overflow: hidden;
    border-radius: 26px;
    position: relative;

    img {
      width: 100%;
    }
  }
  .slick-slider {
    z-index: -1;
    &:hover {
      .text {
        opacity: 1;
      }
    }
  }
  .slick-arrow {
    display: none !important;
  }
`;

function Banner() {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   fade: true,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  return (
    <BannerWrapper id="home">
      <div className="banner-inner pt-16">
        <h1>
          <Flip bottom cascade>
            Welcome To The <br /> AGCC Community
          </Flip>
        </h1>
        <Paragraph>
          <RubberBand>
            AGCC is a collection of nft’s that aims to partner with golf fans,
            golfers, golf organizations, influencers, brands and more to make a
            golfer’s life more enjoyable. AGCC was born out of love for golf.
            Anyone who is a fan of golf should be able to play anywhere In the
            world, and enjoy the splendor of the game. AGCC is a place where you
            can hang out with your friends. Future areas and benefits are
            unlocked through membership. Each member contributes to the benefits
            for ALL members. The combination of the real game in real-life and
            the play to earn. The bridge to the Metaverse through your own
            physical game. Besides enjoying the golf courses and the real game,
            we all want to win.
          </RubberBand>
        </Paragraph>
        <Paragraph>
          <RubberBand>Are you ready for a challenge? Let's tee off</RubberBand>
        </Paragraph>
        {/* <Button link="https://discord.gg" text="join our discord" /> */}
      </div>
      <div className="banner-carousels">
        <div className=" flex justify-center " >
          <img src="gif.gif" alt="" style={{width:"50%"}} />
        </div>
      </div>
    </BannerWrapper>
  );
}

export default Banner;
