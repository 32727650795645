import React from "react";
import styled from "styled-components";
import Heading from "../styled/Heading";
// import Paragraph from "../styled/Paragraph";
import RoadMapItem from "../styled/RoadMapItem";
import Bounce from "react-reveal/Bounce";

const RoadMapWrapper = styled.section`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
  padding: 5%;
  h2{
    width:unset;
    line-height:1.3;
    text-align:center;
    &:before{
      left:50%;
      
      transform:translateX(-50%);
      width:60%;
      @media(min-width:768px){
        width:30%;
      }
      @media(min-width:1200px){
        width:10%;
      }
    }
  }
  /* .span{
      border:3px solid #ffffff;
      max-width:65px;
      display:flex;
      align-items:center;
      justify-content:center;
      font-size:16px;
      padding:5%;
      color:${(props) => props.theme.color.yellow};
      margin-right:30px;
      @media(min-width:768px){
        padding:2%;
        font-size:20px;
      }
  } */
  .timeline {
    padding: 50px 5% 0;
    position: relative;
    @media (min-width: 768px) {
      margin-left: 0;
    }
    @media (min-width: 992px) {
      padding: 50px 0 0;
    }
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 2px;
      background: #ffffff;
      left: 5%;
      top: 0;
      @media (min-width: 992px) {
        left: 50%;
      }
    }
    &:after {
      content: "";
      position: absolute;
      background: #ffffff;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      left: 5%;
      top: 0;
      transform: translateX(-50%);
      @media (min-width: 992px) {
        left: 50%;
      }
    }
    ul {
      &:after {
        content: "";
        position: absolute;
        background: #ffffff;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        left: 5%;
        bottom: 0;
        transform: translateX(-50%);
        @media (min-width: 992px) {
          left: 50%;
        }
      }
    }
  }
`;

function RoadMap() {
  return (
    <RoadMapWrapper id="roadmap">
      <Heading>
        <Bounce left cascade>
          Our RoadMap
        </Bounce>
      </Heading>

      <div className="timeline">
        <ul>
          <RoadMapItem title="First acquaintance AGCC" />

          <RoadMapItem
            title="Build AGCC community
"
            text="GIVEAWAY WHITELIST SPOTS"
            textsecond="whitelist spots 0.05 ETH,
pre-sale spots 0.07 ETH,
public-sale 0.09 ETH"
          />
          <RoadMapItem title="Launch our 5000 AGCC nft’s" text="" />
          <RoadMapItem title="Reveal AGCC" text="" />
          <RoadMapItem
            title="Introduction second collections AGCC nft’s"
            text=" "
          />
          <RoadMapItem
            title="Airdrop"
            text="The players who have bought an AGCC nft from the first 5000 series get a free airdrop to upgrade their AGCC
"
          />
          <RoadMapItem title="Preparations for play to earn" text="" />
          <RoadMapItem title="Launch second collections AGCC upgrade" text="" />
          <RoadMapItem
            title="Merchandise store"
            text="Merchandise store will open. First backers will receive a free item."
          />
          <RoadMapItem
            title="Membership Card (conditions will follow)"
            text=""
          />
        </ul>
      </div>
    </RoadMapWrapper>
  );
}

export default RoadMap;
