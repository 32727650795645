/* eslint-disable import/no-anonymous-default-export */
export default {
  fam: {
    helvetica: "helvetica",
    lulo: "lulo-clean-bold",
    futura: "futura",
    poppinsSemibold: "Poppins-Semibold",
    avenir: "Avenir-Heavy",
    arial: "Arial-Black",
  },
  color: {
    yellow: "#245570",
    grey: "#2e2e2e",
    textgrey: "#a0a09f",
  },
};