import React from "react";
import styled from "styled-components";
import Accordions from "../styled/Accordions";
// import Paragraph from "../styled/Paragraph";
import Heading from "../styled/Heading";
import Bounce from 'react-reveal/Bounce'

const FaqWrapper = styled.section`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
  padding: 5%;
  h2 {
    width: unset;
    line-height: 1.3;
    text-align: center;

    &:before {
      left: 50%;

      transform: translateX(-50%);
      width: 60%;
      @media (min-width: 768px) {
        width: 30%;
      }
      @media (min-width: 992px) {
        width: 10%;
      }
    }
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    background: #245570 !important;
    border: none !important;
    margin-bottom: 30px;
    a {
      text-decoration: none;
      color: #ffffff !important;
    }
  }
  .css-67l5gl {
    background: #245570 !important;
    color: #ffffff;
    @media (min-width: 768px) {
      width: 60% !important;
      margin: 0 auto !important;
      max-width: 500px !important;
    }
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    background: #245570 !important;
    border: 0 !important ;
    @media (min-width: 768px) {
      width: 60% !important;
      margin: 0 auto !important;
      max-width: 500px !important;
    }
    .css-ahj2mt-MuiTypography-root {
      color: #ffffff !important;
      font-family: ${(props) => props.theme.fam.helvetica};
      font-size: 15px !important;
    }
    .css-i4bv87-MuiSvgIcon-root,
    .css-67l5gl {
      color: #ffffff !important;
    }
  }
  .css-15v22id-MuiAccordionDetails-root {
    background: #245570 !important;
    @media (min-width: 768px) {
      width: 60% !important;
      margin: 0 auto !important;
      max-width: 500px !important;
    }
    svg {
      color: ${(props) => props.theme.color.textgrey} !important;
      height: 20px;
      width: 20px;
      margin-right: 30px;
    }
  }
`;

function Faq() {
  return (
    <FaqWrapper id="faq">
      <Heading>
        <Bounce top cascade>
          Faq
        </Bounce>
      </Heading>
      <Accordions
        number="1"
        title="HOW MANY AGCC NFT’S ARE THERE IN THE COLLECTION?"
      >
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          5000 NFT's
        </p>
      </Accordions>
      <Accordions number="2" title="HOW MUCH DOES AN AGCC COST?">
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          WHITELIST 0.05 ETH{" "}
        </p>
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          PRE SALE 0.07 ETH
        </p>
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          PUBLIC SALE 0.09 ETH
        </p>
      </Accordions>
      <Accordions number="3" title="HOW DO I BECOME AN AGCC MEMBER?">
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          You become an AGCC member by buying the AGCC nft. The benefits are
          natural to everyone, however the levels of the benefits are determined
          by various factors. One of those is the score you get in your real
          game. This can be from a match, tournament but also your personal game
          skills throughout your entire playing career. To enjoy all the
          benefits as a member you can use your AGCC nft Application during your
          real game to optimize your membership Benefits and your linked nft in
          both worlds.
        </p>
      </Accordions>
      <Accordions number="4" title="HOW CAN I PURCHASE AN AGCC NFT?">
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          Opensea
        </p>
      </Accordions>
      <Accordions number="5" title="WHAT IS THE CREATORS COLLECTION?">
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          This is the heart of everything we do. We understand what it’s like to
          use your personal style, but the ability to give your all to the game
          as a sports enthusiast can enhance your creative and sporting style.
          Enjoying all the beautiful golf courses around the world at your next
          game.
        </p>
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          Let us help you every step of the way.
        </p>
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          Our goal is to improve the game/experience of every true golfer.
        </p>
      </Accordions>
      <Accordions number="6" title="WHEN IS THE MINTING DATE?">
        <p style={{ color: "#0E095F",  fontSize: "15px" }}>
          Will be announced
        </p>
      </Accordions>
    </FaqWrapper>
  );
}

export default Faq;
