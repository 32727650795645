import styled from "styled-components";
import Heading from "../styled/Heading";
import Paragraph from "../styled/Paragraph";
import React from "react";
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade'

const PillWrapper = styled.section`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
  padding: 10% 5%;
  text-align: center;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: 1200px) {
    padding: 5% 15%;
  }
  h2 {
    font-size: 25px;
    &:before {
      left: 50%;

      transform: translateX(-50%);
      width: 60%;
      @media (min-width: 768px) {
        left: 0;
        transform: unset;
        font-size: 30px;
      }
    }
  }
  p {
    margin-right: auto;
    text-align: left;
  }
  .inner {
    @media (min-width: 768px) {
      width: 45%;
      margin: 0 auto;
    }
  }
  .last {
    text-align: center;
    display: flex;
    align-items: center;
    display: none;
    color: #ffffff;
    font-family: ${(props) => props.theme.fam.helvetica};
    font-size: 15px;
    line-height: 1.3;
    margin: 0 auto;
    justify-content: center;
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 80px;
      object-fit: contain;
    }
  }
  .img {
    width: 100%;
    display: block;
    @media (min-width: 768px) {
      width: 45%;
      display: block;
    }
    img {
      width: 100%;
    }
  }
`;

function Pill() {
  return (
    <PillWrapper id="agcc">
      <div className="inner">
        <Flip left>
          <Heading>AGCC</Heading>
        </Flip>
        <Fade left cascade>
          <Paragraph>
            You become an AGCC member by buying the AGCC nft. The benefits are
            natural to everyone, however the levels Of the benefits are
            determined by various factors. One of those is the score you get in
            your real game. This can be from a match, tournament but also your
            personal game skills throughout your entire playing career.
          </Paragraph>
          <Paragraph>
            To enjoy all the benefits as a member you can use your AGCC nft
            Application during your real game to optimize your membership
            Benefits and your linked nft in both worlds.
          </Paragraph>{" "}
        </Fade>

        <Zoom left cascade>
          <p className="last">
            <span>As Seen On: </span>{" "}
            <a
              href="https://opensea.io/agcc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="OS.png" alt="" style={{ padding: "10px" }} />
            </a>
          </p>
        </Zoom>
      </div>

      <Flip top cascade>
        <div className="img">
          <video
            autoPlay
            loop
            style={{ width: "100%", height: "100%", borderRadius: "25px" }}
          >
            <source src="video2.mov" type="video/mp4" />
            <source src="video2.mov" type="video/mov" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Flip>
    </PillWrapper>
  );
}

export default Pill;
