/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import styled from "styled-components";

import { Link } from "react-scroll";


const Wrapper = styled.footer`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
   a {
      text-decoration: none;
      color: rgb(232, 232, 232);
      font-family: ${(props) => props.theme.helvetica};
      font-size: 18px;
      font-weight: bold;
      padding: 0 10px;
    }
  `;


function Header() {
  

  return (
    <Wrapper>
      <header class="text-gray-600 body-font">
        <div class="container mx-auto flex flex-wrap px-5 flex-col md:flex-row items-center">
          <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            <img
              src="logo.gif"
              alt="Pill syndrome"
              style={{ height: "100px" }}
            />
          
          </a>
          <nav class="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center ">
            
              <Link
                to="home"
                activeClass="active"
                spy={true}
                smooth={true}
                duration={500}
              >
                ABOUT
              </Link>
            
            
              <a
                href="https://mint.acegolfcountryclub.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                MINT
              </a>
            

            
              <Link
                activeClass="active"
                to="team"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                TEAM
              </Link>
            
            
              <Link
                activeClassName="active"
                to="roadmap"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
            ROADMAP
              </Link>
            
            
              <Link
                activeClassName="active"
                to="agcc"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                AGCC
              </Link>
            

            
              <Link
                activeClassName="active"
                to="faq"
                spy={true}
                smooth={true}
                offset={-20}
                duration={500}
              >
                FAQ
              </Link>
            
          </nav>
          <div class="inline-flex items-center  border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
     
              <a href="https://www.instagram.com/acegolfcountryclub">
                <FiInstagram />
              </a>
              <a href="https://twitter.com/agccmembersonly">
                <BsTwitter />
              </a>
            
          </div>
        </div>
      </header>
    </Wrapper>
  );
}

export default Header;
