import styled from "styled-components";
import React from "react";

const Wrapper = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fam.helvetica};
  font-size: 13px;
  line-height: 1;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 1.3;
  }
`;

function Paragraph({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

export default Paragraph;
