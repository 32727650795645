import styled from "styled-components";
import Heading from "../styled/Heading";
import PerkCheck from "../styled/PerkCheck";
// import Video from "../styled/Video";
import React from "react";
import RubberBand from "react-reveal/RubberBand";
import Paragraph from "../styled/Paragraph";

const PerkWrapper = styled.section`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
  padding: 5%;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media (min-width: 1200px) {
    padding: 5% 15%;
  }
  video {
    width: 100%;
  }
  .video-react {
    display: none;
    @media (min-width: 768px) {
      display: block;
      width: 45%;
      padding-top: 45.56% !important;
    }

    .video-react-control-bar {
      background: transparent;
    }
  }

  h2 {
    font-size: 25px;

    &:before {
      left: 50%;

      transform: translateX(-50%);
      width: 60%;
    }
  }
  .perks-inner {
    margin-top: 30px;
    @media (min-width: 768px) {
      width: 45%;
      margin-top: 0;
    }
  }
  .gif {
    display: none;
    width: 100%;
    max-width: 100%;
    border-radius: 25px;
    @media (min-width: 768px) {
      display: block;
      max-width: 35%;
      width: 100%;
      padding: 20px;
      background-color: #f5f5f5;
    }
  }
  .gig {
    display: block;
    width: 100%;
    max-width: 50%;
    border-radius: 25px;
    padding: 10px;
    background-color: #f5f5f5;
    @media (min-width: 768px) {
      display: none;
      max-width: 35%;
      width: 100%;
      padding: 20px;
      background-color: #f5f5f5;
    }
  }
`;

function Perks() {
  return (
    <PerkWrapper>
      <img src="card.png" className="gif" alt="" />
      <div className="flex justify-center">
        <img src="card.png" className="gig" alt="" />
      </div>

      <div className="perks-inner">
        <RubberBand>
          <Heading>Perks</Heading>
        </RubberBand>
        <Paragraph>Our AGCC Member gets access to:</Paragraph>
        <div className="text">
          <PerkCheck
            description="Membership Cards(conditions will follow)
"
          />
        </div>
      </div>
    </PerkWrapper>
  );
}

export default Perks;
