import styled from "styled-components";
import React from "react";
import Slide from 'react-reveal/Slide';

const Wrapper = styled.section`
  background: #245570;
  padding: 10% 5%;
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 5% 5%;
    align-items: center;
  }
  .inner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    &:first-child {
      margin-bottom: 30px;
      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }
    @media (min-width: 768px) {
      margin-right: 50px;
      flex-direction: row;
      width: max-content;
    }
    p {
      text-transform: uppercase;
      color: ${(props) => props.theme.color.textgrey};
      font-family: ${(props) => props.theme.fam.futura};
      font-size: 15px;
      margin-right: 0;
      margin-top: 0;
      @media (min-width: 768px) {
        margin: 0 25px 0 0;
        font-size: 18px;
      }
    }
    span {
      font-family: ${(props) => props.theme.fam.poppinsSemibold};
      color: #ffffff;
      font-size: 30px;
    }
  }
`;

function Prize() {
  

  return (
    <Wrapper>
      <div className="inner">
        <Slide left>
          <p>DROP COUNT</p>
        </Slide>
        <Slide right>
          <span>5000</span>
        </Slide>
      </div>
      <div className="inner">
        <Slide left>
          <p>PRICE</p>
        </Slide>
        <Slide right>
          <span>0.09 ETH</span>
        </Slide>
      </div>

    </Wrapper>
  );
}

export default Prize;
