import React from "react";
import { BsChevronRight, BsTwitter } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import styled from "styled-components";
import Heading from "../styled/Heading";
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip'

const FooterWrapper = styled.footer`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
  padding: 5%;
  @media (min-width: 768px) {
    display: flex;
  }
  @media (min-width: 1200px) {
    padding: 5% 15%;
  }
  .footer-inner {
    text-align: left;
    margin-bottom: 30px;
    @media (min-width: 768px) {
      width: 45%;
    }
    h2 {
      margin: 0 0 30px;
      @media (min-width: 768px) {
        font-size: 20px;
      }
    }
    a {
      text-decoration: none;
      color: rgb(232, 232, 232);
      font-family: ${(props) => props.theme.helvetica};
      font-size: 18px;
    }

    .social-links,
    .links {
      display: flex;
      justify-content: space-between;
      width: 120px;
      margin-top: 30px;
      @media (min-width: 768px) {
        display: none;
      }
      svg {
        height: 30px;
        width: 30px;
      }
    }
    .social-links {
      display: none;
      @media (min-width: 768px) {
        display: flex;
        width: 120px;
        margin-top: 30px;
      }
      svg {
        height: 30px;
        width: 30px;
      }
    }
    form {
      width: 100%;
      max-width: 400px;
      display: flex;
      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
      input {
        width: 85%;
        height: 50px;
        border: none;
        padding: 0 10px;
        font-weight: 900;
        color: #000000;
        font-family: ${(props) => props.theme.fam.helvetica};
        &::placeholder {
          color: #000000;
        }
      }
      button {
        height: 50px;
        width: 15%;
        border: none;
        background: #ffffff;
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    .last {
      text-align: left;
      display: flex;
      align-items: center;
      color: #ffffff;
      font-family: ${(props) => props.theme.fam.helvetica};
      font-size: 15px;
      line-height: 1.3;
      margin: 0 auto;
      justify-content: flex-start;
      img {
        width: 80px;
        object-fit: contain;
      }
    }
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <div className="footer-inner">
        <Heading>
          <Bounce left cascade>
            Contact Us
          </Bounce>
        </Heading>

        <div className="social-links">
          <a href="https://www.instagram.com/acegolfcountryclub">
            <FiInstagram />
          </a>
          <a href="https://twitter.com/agccmembersonly">
            <BsTwitter />
          </a>
        </div>
      </div>
      <div className="footer-inner">
        <Heading>
          <Bounce left cascade>
            Mailing List
          </Bounce>
        </Heading>
        <form action="">
          <input type="text" placeholder="Email*" />{" "}
          <button type="submit">
            <BsChevronRight />
          </button>
        </form>
        <div className="links">
          <a href>
            <FiInstagram />
          </a>
          <a href>
            <BsTwitter />
          </a>
        </div>
        <Flip left cascade>
          <p className="last">
            <span>As Seen On:</span>{" "}
            <a href="https://opensea.io/agcc" target="_blank" rel="noopener noreferrer">
              <img src="OS.png" alt="" style={{ padding: "10px" }} />
            </a>
          </p>
        </Flip>
      </div>
    </FooterWrapper>
  );
}

export default Footer;
