/* eslint-disable */

import React from "react";
import styled from "styled-components";
import Heading from "../styled/Heading";
import TeamCard from "../styled/TeamCard";
import Jello from 'react-reveal/Jello';
import Fade from 'react-reveal/Fade'

const TeamWrapper = styled.section`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
  padding: 5%;
  h2 {
    margin-bottom: 30px;
    line-height: 1.3;
    margin: 0 auto 50px;
    &:before {
      left: 50%;

      transform: translateX(-50%);
      width: 60%;
      @media (min-width: 768px) {
        width: 30%;
      }
    }
  }
  .team-inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;

function Teams() {
  return (
    <TeamWrapper id="team">
      <Jello>
        <Heading>Meet The Team</Heading>
      </Jello>
      <Fade top cascade>
        <div className="team-inner">
          <TeamCard
            imageUrl="4.png"
            title="Driver"
            role="Owner & Creator"
            nickName="@dp"
            // link="https://twitter.com/dp"
          />
          <TeamCard
            imageUrl="5.png"
            title="Wood 3"
            role="Graphic Designer"
            nickName="@dp & @JenniferS"
            // link="https://twitter.com/dp"
          />
          <TeamCard
            imageUrl="2.png"
            title="Wood 5"
            role="Developer"
            nickName="@CyprianAarons"
            // link="https://twitter.com/CyprianAarons"
          />
          <TeamCard
            imageUrl="1.png"
            title="Pitching Wedge"
            role="Marketing Manager"
            nickName="@JohnnyD"
            // link="https://twitter.com/JohnnyD"
          />
          <TeamCard
            imageUrl="3.png"
            title="Putter"
            role="Advisor"
            nickName="@BrandonG & @ CarinD"
            // link="https://twitter.com/BrandonG"
          />
        </div>
      </Fade>
    </TeamWrapper>
  );
}

export default Teams;
