import React from "react";
import styled from "styled-components";
import Heading from "../styled/Heading";
import Paragraph from "../styled/Paragraph";
// import Bounce from "react-reveal/Bounce";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

const StoryModeWrapper = styled.section`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
  padding: 5%;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  @media (min-width: 1200px) {
    padding: 5% 15%;
  }
  h2 {
    width: 100%;
    line-height: 1.3;
    text-align: center;
    &:before {
      left: 50%;

      transform: translateX(-50%);
      width: 60%;
      @media (min-width: 768px) {
        width: 40%;
      }
      @media (min-width: 1200px) {
        width: 15%;
      }
    }
  }
  .img {
    width: 100%;
    border-radius:10px;
    @media (min-width: 768px) {
      width: 45%;
    }
    img {
      width: 100%;
      border-radius:10px;
    }
  }
  .inner {
    text-align: center;
    @media (min-width: 768px) {
      width: 45%;
      text-align:left;
      20px solid 29px;
    }
  }
`;

function StoryMode() {
  return (
    <StoryModeWrapper id="story">
      <Heading>
        {" "}
        <Slide top cascade>
          MERCHANDISE & MEMBERSHIP
        </Slide>
      </Heading>
      <Fade bottom cascade>
        <div className="img">
          <img src="agcc.png" alt="" />
        </div>
      </Fade>

      <div className="inner">
        <Fade left>
          <Paragraph>Our goal...</Paragraph>
          <Paragraph>
            Everything that has to do with golf and to assist the golfer
            optimally to get the best of your game!
          </Paragraph>
        </Fade>
      </div>
    </StoryModeWrapper>
  );
}

export default StoryMode;
