import React from "react";
import styled from "styled-components";
import Heading from "../styled/Heading";
import Paragraph from "../styled/Paragraph";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Fade from "react-reveal/Fade";
import RubberBand from "react-reveal/RubberBand";

const SpecialWrapper = styled.section`
  background: url("blue.png");
  background-size: cover;
  background-position: center;
  padding: 5%;
  text-align: center;
  @media (min-width: 768px) {
    .item-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  @media (min-width: 1200px) {
    padding: 5% 15%;
  }
  img {
    width: 100%;
    border-radius: 26px;
  }
  svg {
    color: #ffffff;
    height: 40px;
    width: 40px;
  }

  h2 {
    font-size: 25px;
    width: unset;
    line-height: 1.3;
    @media (min-width: 768px) {
      width: 100%;
      text-align: left;
    }
    &:before {
      left: 50%;

      transform: translateX(-50%);
      width: 60%;
      @media (min-width: 768px) {
        left: 0;
        transform: unset;
        width: 30%;
      }
    }
  }
  .inner {
    @media (min-width: 768px) {
      width: 40%;
    }
  }
  .slick-slider {
    @media (min-width: 768px) {
      width: 50%;
      margin: 0 auto;
    }
  }
  .item {
    position: relative;
    @media (min-width: 768px) {
      width: 60%;
      img {
        width: 100%;
      }
    }
    &:hover {
      .text {
        opacity: 1;
      }
    }
    .text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transition: 0.7s;
      z-index: 9;
      border-radius: 0 0 26px 26px;
      opacity: 0;
      color: #696969;
      background: #ffffff;
    }
  }
  .slick-prev {
    left: 0;
    z-index: 5;
  }
  .slick-next {
    right: 0;
    z-index: 5;
  }
`;

function Special() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    nextArrow: <BsChevronRight />,
    prevArrow: <BsChevronLeft />,
    slidesToScroll: 1,
  };
  return (
    <SpecialWrapper>
      <RubberBand>
        <Heading>CREATORS COLLECTION</Heading>
      </RubberBand>

      <div className="item-inner">
        <div className="inner">
          <Fade left>
            <Paragraph>
              We have many collaborations head. Each member contributes to the
              benefits for ALL members. Further details to be announced.
            </Paragraph>
          </Fade>
        </div>

        <Slider {...settings}>
          <div className="item">
            <video
              autoPlay
              loop
              style={{ width: "100%", height: "100%", borderRadius: "25px" }}
            >
              <source src="video1.mov" type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <div className="text">
              <h3>AGCC</h3>
            </div>
          </div>
        </Slider>
      </div>
    </SpecialWrapper>
  );
}

export default Special;
